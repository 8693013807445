import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './Tariffs.css'
import { formatDate } from '../utils/formatDateTime';
import { formatTimeToMoscow } from '../utils/formatDateTime';
import { Context } from '../context/GlobalContext';

const Tariffs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tokensData, setTokensData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const { user } = useContext(Context);

    useEffect(() => {
        fetchTokensData();
    }, []);

    const fetchTokensData = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/thingy-tokens?limit=20`); // Ensure your backend supports this query
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setTokensData(data); // Assuming data is an array of objects
        } catch (error) {
            console.error('Error fetching tokens data:', error);
        }
    };

    return (
        <div className='wrapper'>
            <div className='wrapper1' style={{ marginTop: '70px' }}>
                <div className="tab-block-header">
                    <div
                        style={{ marginRight: '10px', transform: 'translateY(4px)' }}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBackIosNewRoundedIcon />
                    </div>
                </div>
                <div><span>Ваш баланс: </span><span style={{fontSize: '22px', fontWeight: '500'}}>{user.token_amount}</span></div>

                <div style={{marginTop: '20px', marginBottom: '10px', fontSize: '18px', fontWeight: '500'}}>Ваши последние 20 транзакций:</div>
                <div className="tokens-container">
                    {tokensData.map((transaction, index) => (
                        <div className="token-card" key={index}>
                            <div className='token-card-left'>
                                <h3>{transaction.thingy_name}</h3>
                                <p>{transaction.project}</p>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                    <p style={{
                                        marginRight: '20px',
                                        color: transaction.inout === 'out' ? 'green' : 'red',
                                        fontWeight: '500'
                                    }}>
                                        {transaction.inout === 'out' ? '+' : '-'} {transaction.tokens}</p>
                                </div>
                                <div>
                                    <p style={{opacity: '0.7', fontWeight: '400', fontSize: '12px'}}>{formatDate(transaction.date_created)}</p>
                                    <p style={{opacity: '0.7', fontWeight: '400', fontSize: '12px'}}>{formatTimeToMoscow(transaction.time_created)} МСК</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <button className='action-button' style={{ position: 'fixed', bottom: '20px', width: '90%' }} onClick={() => window.open('/tokens', '_blank')}>Получить токены</button>
            </div>
        </div>
    );
};

export default Tariffs;
