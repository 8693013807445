// src/components/ConfirmationModal.js
import React from 'react';
import './ConfirmationModal.css'; // Ensure your styles are applied

const ConfirmationModal = ({ title, text, onConfirm, onCancel, closeModal, confirmLabel, cancelLabel }) => {
    // Close the modal when clicking outside of the modal content
    const handleBackdropClick = (event) => {
        if (event.target.classList.contains('confirmation-modal-backdrop')) {
            closeModal();
        }
    };

    return (
        <div className="confirmation-modal-backdrop" onClick={handleBackdropClick}>
            <div className="confirmation-modal">
                <h2>{title}</h2>
                <p>{text}</p>
                <div className="modal-buttons">
                    <button onClick={onCancel} className="cancel-button">{cancelLabel}</button>
                    <button onClick={onConfirm} className="action-button">{confirmLabel}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
