// src/components/Confirmation.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Wait.css';

const Wait = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const { header, description, additionalField1, additionalField2, additionalField3 } = location.state || {};

    const handleSubmit = () => {
        // Simulate final submission, you can replace this with an actual API call
        const formData = {
            header,
            description,
            additionalField1,
            additionalField2,
            additionalField3
        };

        console.log("Submitting form data:", formData);
        // Navigate to a success or home page after submission
        navigate('/', {
            state: {
                header,
                description,
                additionalField1,
                additionalField2,
                additionalField3
            }
        });
    };

    return (
 
            <div className='loading-process'>
                <div className="spinner">
                    <div className="circle">
                        <div className="inner"></div>
                    </div>
                    <div className="circle">
                        <div className="inner"></div>
                    </div>
                    <div className="circle">
                        <div className="inner"></div>
                    </div>
                    <div className="circle">
                        <div className="inner"></div>
                    </div>
                    <div className="circle">
                        <div className="inner"></div>
                    </div>
                </div>
                <h1 style={{marginTop: '20px', marginBottom: '10px'}}>Ваш запрос обрабатывается...</h1>
                <div>Обычно это занимает меньше минуты</div>
            </div>

    );
};

export default Wait;
