import { useState, useCallback, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../context/GlobalContext';
import { verifyTgData, fetchUserProjects } from '../utils/api'; // Ensure to import fetchUserProjects

const UserFetcher = ({ setLoading }) => {
    const { user, setUser, setProjects } = useContext(Context);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [isFetched, setIsFetched] = useState(false);
    const navigate = useNavigate();

    const fetchUserInLocal = useCallback(() => {
        const testUserId = '751b788f-e1a7-405e-a3bb-003d3c8a6bcf';
        console.log('Local mode: Fetching user data.');

        axios.get(`${apiUrl}/api/user`, {
            headers: {
                'user-id': testUserId
            }
        })
        .then(response => {
            setUser(response.data.user);
            console.log('User fetched for local environment:', response.data.user);
            setLoading(false);
            setIsFetched(true);

            // Fetch user projects directly here
            return fetchUserProjects(testUserId); // Fetch projects
        })
        .then(projects => {
            if (projects.length > 0) {
                setProjects(projects); // Store the fetched projects in context

                console.log('Fetched user projects:', projects);
            } else {
                console.log('No projects');
                navigate('/onboarding');
            }

        })
        .catch(error => {
            console.error('Error fetching user in local environment:', error);
            setLoading(false);
        });
    }, [apiUrl, setUser, setLoading, setProjects]);

    const fetchUserInDevelopment = useCallback(async (initData) => {
        console.log('Development mode: Fetching user data.');
        try {
            const response = await verifyTgData(initData); // Call verifyTgData with initData
            if (response.success) {
                setUser(response.userData); // Set user data from backend response
                console.log('User data set from backend:', response.userData);

                // Fetch user projects directly here
                const projects = await fetchUserProjects(response.userData.id);
                if (projects.length > 0) {
                    setProjects(projects); // Store the fetched projects in context
                    navigate('/library');
                    console.log('Fetched user projects:', projects);
                } else {
                    console.log('No projects');
                    navigate('/onboarding');
                }

            } else {
                console.error('Error from backend:', response.message);
                navigate('/library'); //ИЗМЕНИТЬ НА ROOT ROUTE
            }
            setLoading(false);
            setIsFetched(true);
        } catch (error) {
            console.error('Error verifying user in development environment:', error);
            setLoading(false);
        }
    }, [setUser, setLoading, setProjects, navigate]);

    useEffect(() => {
        const environment = process.env.REACT_APP_ENV;

        if (user) {
            // If user is already set, skip fetching
            setLoading(false);
            return;
        }
        if (!isFetched) {
            if (environment === 'local') {
                fetchUserInLocal();
            } else if (environment === 'development') {

                    const tg = window.Telegram.WebApp;
                    tg.ready();
                    tg.expand();
                    tg.disableVerticalSwipes();
                    fetchUserInDevelopment(tg.initData);
                
            } else if (environment === 'production') {
                
                const tg = window.Telegram.WebApp;
                tg.ready();
                tg.expand();
                tg.disableVerticalSwipes();
                fetchUserInDevelopment(tg.initData);
            }
        }
    }, [user, isFetched, fetchUserInDevelopment, fetchUserInLocal, setLoading]);

    return null;
};

export default UserFetcher;
