import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { updateProject } from '../utils/api';
import { Context } from '../context/GlobalContext';
import ConfirmationModal from '../components/ConfirmationModal';
import './CreateProject.css';

const EditProject = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { projectId, projectName: initialProjectName, projectDescription: initialProjectDescription } = location.state || {};

    const { user, setProjects, setSelectedProject } = useContext(Context); // Add setSelectedProject to context

    const [projectName, setProjectName] = useState(initialProjectName || '');
    const [projectDescription, setProjectDescription] = useState(initialProjectDescription || '');
    const [showModal, setShowModal] = useState(false);

    const handleUpdate = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: true
            });

            if (response && response.message === 'OK') {
                console.log('Project updated:', response.project);

                // Update the projects in the context
                setProjects(prevProjects => 
                    prevProjects.map(project => 
                        project.id === projectId ? { ...project, name: projectName, description: projectDescription } : project
                    )
                );

                // Set the updated project as the selected project
                setSelectedProject({ id: projectId, name: projectName, description: projectDescription });

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to update project');
        }
    };

    const handleDeleteProject = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: false
            });

            if (response && response.message === 'OK') {
                console.log('Project deactivated:', response.project);

                // Remove the project from the context
                setProjects(prevProjects => 
                    prevProjects.filter(project => project.id !== projectId)
                );

                // Optionally reset selected project
                setSelectedProject(null);

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to delete project');
        }
    };

    const getFontSize = (length) => {
        if (length > 499) return '14px';
        if (length > 99 && length < 500) return '16px';
        if (length > 49 && length < 100) return '18px';
        if (length > 19 && length < 50) return '20px'; 
        if (length < 20) return '26px'; 
        return '20px'; 
    };

    return (
        <div className='wrapper'>
            <div className='wrapper1'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdate();
                    }}
                >
                    <div className="tab-block-header">
                        <div
                            style={{ marginRight: '10px', transform: 'translateY(4px)' }}
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </div>
                        <h1>Настройки проекта</h1>
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectName">Название проекта:</label>
                        <textarea className='create-project-input'
                            type="text"
                            id="projectName"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            maxLength="30"
                            style={{
                                width: '100%',
                                overflowY: 'auto',
                                fontSize: getFontSize(projectName.length) // Dynamic font size for project name
                            }}
                            placeholder="Мой проект"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectDescription">Описание проекта:</label>
                        <textarea className='create-project-input1'
                            id="projectDescription"
                            value={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            style={{
                                overflowY: 'auto',
                                fontSize: getFontSize(projectDescription.length),
                                marginBottom: '20px'
                            }}
                            placeholder="Описание моего проекта"
                            required
                        />
                    </div>
                    <button type="submit" className="action-button">Сохранить</button>
                    <button
                        type="button"
                        style={{width: '100%', textAlign: 'center', border: 'none', background: 'none', textDecoration: 'underline', color: 'red', marginTop: '20px', fontSize: '18px', fontWeight: '500', fontFamily: '"Jost", sans-serif'}}
                        onClick={() => setShowModal(true)}
                    >
                        Удалить
                    </button>
                </form>

                {showModal && (
                    <ConfirmationModal
                        title="Точно удалить?"
                        text="Нельзя вернуть то, что удалено"
                        onConfirm={handleDeleteProject}
                        onCancel={() => setShowModal(false)}
                        closeModal={() => setShowModal(false)}
                        cancelLabel="Нет"
                        confirmLabel="Да"
                    />
                )}
            </div>
        </div>
    );
};

export default EditProject;
