import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Context } from '../context/GlobalContext';
import LoadingState from '../components/LoadingState';
import { TelegramThemeContext } from '../context/TelegramThemeContext';
import StatusBar from '../components/StatusBar';
import { io } from 'socket.io-client';
import { updateUserBalance } from '../utils/api';
import './Assistant.css'
import { findAllByLabelText } from '@testing-library/react';

const Assistant = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, name, description, field, hint, prompt, fieldValue: initialFieldValue = '' } = location.state || {};
    const { user, selectedProject, updateTokensInContext } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [streamedData, setStreamedData] = useState('');
    const [error, setError] = useState(null);
    const [fieldValue, setFieldValue] = useState(initialFieldValue || '');
    const socketRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 100;
    const [fullResponse, setFullResponse] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [pdfSent, setPdfSent] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    let accumulatedResponse = '';
    const bot_url = process.env.REACT_APP_BOT_URL;
    const [balance, setBalance] = useState('');

    const theme = useContext(TelegramThemeContext);

    /*const handleSocketErrorFallback = async () => {
        setIsLoading(true);
        setStreamedData('');
        try {
            const response = await fetch(`${apiUrl}/api/noSocketHandlePrompt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id,
                    selectedProject,
                    fieldValue,
                    stream: true, // Set true to enable streaming from backend
                }),
            });

            if (!response.ok) {
                throw new Error('HTTP error: ' + response.status);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedResponse = '';

            // Start reading the stream
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });

                // Update streamed data
                setStreamedData((prev) => prev + chunk);

                console.log('Chunk received:', chunk);
                accumulatedResponse += chunk;
            }

            // When the stream finishes, save the full response
            setFullResponse(accumulatedResponse);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching from fallback:', error);
            setError('Error fetching prompts. Please try again.');
            setIsLoading(false);
        }
    };*/

    const updateBalance = async () => {
        const userId = user.id;
        const cash = 'out';
        console.log(userId, balance);
        try {
            const data = await updateUserBalance(user.id, id, name, selectedProject.name, balance, cash);

            const newBalance = user.token_amount - data.tokenRecord.tokens;  // Subtract tokensUsed from current balance
            updateTokensInContext(newBalance);

            console.log('Balance updated successfully', data);
        } catch (error) {
            console.error('Error updating balance');
        }
    };

    useEffect(() => {
        // Check if fullResponse is set and then invoke the async function
        if (fullResponse) {
            updateBalance();
        }
    }, [fullResponse, balance]);

    const handleSubmit = async (e) => {
        console.log('handle submit')
        e.preventDefault();
        setStreamedData('');
        const finalPrompt = `selectedProject: ${selectedProject.name}, ${selectedProject.description}, fieldValue: ${fieldValue}, ${prompt}`;
        console.log(finalPrompt)

        const eventSource = new EventSource(`https://cookiespooky-gpt4-proxy.hf.space/stream?prompt=${encodeURIComponent(finalPrompt)}`);
        console.log('eventSource sent')

        eventSource.onmessage = (event) => {
            console.log('receiving')
            let data = event.data;
            // Replace each numbered message with a line break after the number and quotation mark
            data = data.replace(/(\d+)\.\s/g, '\n$1. ');  // Add newline before each numbered list item

            // Add extra newlines after each sentence (after the closing quote)
            data = data.replace(/"(\d+\.)/g, '"\n');  // Add a newline after closing quotes and before the next numbered item

            if (event.data.includes('[Stream Ended]')) {
                
                console.log('Stream ended, closing connection');
                eventSource.close();  // Explicitly close the connection to avoid reconnection attempts
                
                

            } else if (event.data.includes('Использовано токенов')) {
                const tokenUsage = event.data.match(/Использовано токенов: (\d+)/);
                if (tokenUsage) {
                    const tokensUsed = parseInt(tokenUsage[1], 10);
                    setBalance(tokensUsed);  // Update the token balance in the UI
                    console.log(`Tokens used: `, tokensUsed);
                    
                }

                eventSource.close();
                console.log('EventSource connection closed');
                console.log(accumulatedResponse)
                setFullResponse(accumulatedResponse);
            } else {
                setStreamedData((prev) => prev + event.data);  // Append the streamed data
                accumulatedResponse += event.data
            }
        };

        eventSource.onerror = (err) => {
            console.log('ReadyState:', eventSource.readyState);

            if (eventSource.readyState === EventSource.CLOSED) {
                console.log('SSE connection closed normally.');
            } else if (eventSource.readyState === EventSource.CONNECTING) {
                console.log('SSE connection is trying to reconnect.');
            } else {
                // If it's neither closed nor reconnecting, log the error
                console.error('SSE Error: Connection interrupted or failed:', err);
                setError('An error occurred while receiving data. Please try again later.');
            }

            eventSource.close();
        }
    };

    /*const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setStreamedData('');
    
        // Establish Socket.IO connection
        const socket = io('wss://0807045.xn----7sbqanjfgar4cffc4dn.xn--p1ai', {
            path: '/socket.io',
            transports: ['websocket'],  // Use WebSocket transport
            reconnection: true,         // Enable automatic reconnection
            reconnectionAttempts: 5,    // Max reconnection attempts
        });
    
        socketRef.current = socket;
    
        // Handle successful connection
        socket.on('connect', () => {
            console.log('Socket.IO connection established');
            // Send request data to the server
            socket.emit('request_prompt', {
                id,
                selectedProject,
                fieldValue,
                userId: user.id,
            });
        });
    
        // Handle incoming streamed data from the server
        socket.on('stream_chunk', (chunk) => {
            console.log('Received chunk:', chunk);
            setStreamedData((prev) => prev + chunk);
        });
    
        // Handle when the streaming is finished
        socket.on('stream_done', () => {
            console.log('Streaming finished');
            setIsLoading(false);  // Stop loading once the stream is done
        });
    
        // Handle token update (if applicable)
        socket.on('token_update', (data) => {
            console.log('Token update:', data.updatedBalance);
            setBalance(data.updatedBalance);  // Update token balance
        });
    
        // Handle disconnection
        socket.on('disconnect', () => {
            console.log('Socket.IO connection disconnected');
        });
    
        // Handle errors
        socket.on('error', (err) => {
            console.error('Socket.IO error:', err);
            setError('An error occurred while connecting to the server.');
            setIsLoading(false);  // Stop loading on error
        });
    };/*
    
    /*const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setStreamedData('');

        if (socketRef.current) {
            socketRef.current.close();
        }

        const socket = new WebSocket(process.env.REACT_APP_WS_URL, ['binary']);
        socketRef.current = socket;

        socket.onopen = () => {
            console.log('WebSocket connection opened', socket.readyState);
            if (socket.readyState === 1) {
                socket.send(JSON.stringify({
                    id,
                    selectedProject,
                    fieldValue,
                    userId: user.id,
                }));
            } else {
                console.log('WebSocket not in open state:', socket.readyState);
            }
        };

        socket.onmessage = (event) => {
            console.log('Message from server:', event.data);
            const message = event.data;
            if (typeof message === 'string') {
                try {
                    // Attempt to parse the string as JSON
                    const parsedData = JSON.parse(message);
                    console.log('Received valid JSON:', parsedData);
                    if (parsedData.type === 'TOKEN_UPDATE') {
                        // Update the token balance in the frontend
                        //setBalance(parsedData.updatedBalance);
                        console.log('updated balance: ', parsedData.updatedBalance);
                    }
                } catch (error) {
                    console.log('Received a non-JSON string:', message);
                    if (event.data === 'DONE') {
                        console.log('Stream finished');
                        setFullResponse(accumulatedResponse);
                        setIsLoading(false);
                        console.log('Full Response:', accumulatedResponse);
                    } else {
                        // Update streamed data as it arrives
                        setIsLoading(false);
                        accumulatedResponse += event.data;
                        setStreamedData((prev) => prev + event.data);
                    }
                }
            } else {
                console.log('Received non-string data:', message);
            }
        };

        socket.onerror = (err) => {
            console.error('WebSocket error details:', {
                code: err.code,
                reason: err.reason,
                message: err.message,
            });
            setError(`WebSocket error: ${err.message}`);
            //handleSocketErrorFallback();
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed.');
            console.log(`Code: ${event.code}, Reason: ${event.reason || 'No reason provided'}`);
            if (event.code !== 1000) { // 1000 means "normal closure"
                //handleSocketErrorFallback();
                console.error('WebSocket closed unexpectedly with code:', event.code);
            }
        };

        // Clean up WebSocket when the component unmounts
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    };*/

    const toggleShowMore = () => {
        setIsExpanded(!isExpanded);
    };//

    const handleGetPdf = async () => {
        setIsButtonLoading(true);
        const chatId = user.telegram_id;
        console.log(chatId, fullResponse)
        try {
            const response = await fetch(`${apiUrl}/api/getPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatId: chatId,
                    content: fullResponse
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setPdfSent(true);
            setIsButtonLoading(false);
        }
        catch (error) {
            console.error('Error while generating pdf:', error);
            setIsButtonLoading(false);
        }
    }

    const getFontSize = (length) => {
        if (length > 499) return '14px';
        if (length > 99 && length < 500) return '16px';
        if (length > 49 && length < 100) return '18px';
        if (length > 19 && length < 50) return '20px';
        if (length < 20) return '26px';
        return '20px';
    };

    return (
        <div className="wrapper">
            <StatusBar streamedData={streamedData} fullResponse={fullResponse} />
            <div className="wrapper1" style={{ marginTop: '0px' }}>
                {isLoading ? (
                    <LoadingState />
                ) : (
                    <div>
                        <h1>{name}</h1>
                        <h2>{description} для проекта "{selectedProject?.name || '...'}"</h2>
                        <div>
                            <div className='bubble-wrapper'>
                                <img className='thingy-img' src={`${process.env.PUBLIC_URL}/assets/thingy.png`} alt="thingy" />
                                <div className='bubble-text'>
                                    {streamedData ? (
                                        <div>
                                            <div className="streamed-response" style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
                                                <h3>Streaming Data:</h3>
                                                <p>{streamedData}</p>
                                            </div>
                                            {error && (
                                                <div>
                                                    <div className="error-message">{error}</div>
                                                    <button className='action-button' onClick={handleSubmit}>
                                                        {isButtonLoading ? 'Загрузка...' : 'Попробовать снова'}
                                                    </button>
                                                </div>
                                            )}
                                            <div>
                                                {fullResponse && !pdfSent && (
                                                    <button style={{ border: 'none', background: 'none', fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline', color: 'white' }} onClick={handleGetPdf}>
                                                        {isButtonLoading ? 'Загрузка...' : 'Скачать PDF'}
                                                    </button>
                                                )}
                                                {pdfSent && <div className="pdf-sent-message" style={{ textAlign: 'left', fontWeight: '500', marginTop: '10px', fontSize: '16px' }}>PDF-документ отправлен в <a style={{ textDecoration: 'underline', color: '#555', }} href={process.env.REACT_APP_BOT_URL}>бот</a> </div>}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {/*<div style={{marginBottom: '10px', fontSize: '14px', fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace'}}>Подсказка</div>*/}
                                            {hint}
                                        </div>)}
                                    {error ? (<div>{error}</div>) : (null)}
                                </div>
                            </div>
                        </div>
                        {!streamedData && (
                            <form onSubmit={handleSubmit} className="project-form">
                                <div className="form-group">
                                    <textarea
                                        type="text"
                                        id="additionalField1"
                                        value={fieldValue}
                                        onChange={(e) => setFieldValue(e.target.value)}
                                        placeholder="Ваш ответ"
                                        style={{
                                            fontSize: getFontSize(fieldValue.length),
                                            border: '2px solid rgba(0,0,0,0.3)',
                                            marginTop: '20px',
                                            padding: '8px 14px'
                                        }}
                                        required
                                    />
                                </div>
                                <button type="submit" className="gradient-button">
                                    Начать
                                    <span className="gradient-button-icon">✨</span>
                                </button>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
};

export default Assistant;