import React from 'react';
const Home = () => {

  

  return (
    <div className='wrapper'>
      <div className='wrapper1'>
        <h1>Home</h1>
      </div>
    </div>
  );
};

export default Home;
