import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './CreateProject.css';
import LoadingState from '../components/LoadingState';

const Onboarding = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { user, projects, setProjects, setSelectedProject: setGlobalSelectedProject } = useContext(Context);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const navigate = useNavigate();

    const inputRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
        // Dynamically adjust input width based on content
        if (inputRef.current) {
            inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
        }
    }, [projectName]);

    useEffect(() => {
        // Dynamically adjust textarea height based on content
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [projectDescription]);

    // Function to dynamically resize font based on text length
    const getFontSize = (length) => {
        if (length > 499) return '14px';
        if (length > 99 && length < 500) return '16px';
        if (length > 49 && length < 100) return '18px';
        if (length > 19 && length < 50) return '20px'; 
        if (length < 20) return '26px'; 
        return '20px'; 
    };

    // Show filtered projects when the input field is focused
    const handleFocus = () => {
        setFilteredProjects(projects);
    };

    // Update the input field and filter the projects as the user types
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setProjectName(inputValue);

        // Filter projects based on the input
        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredProjects(filtered);
        setSelectedProject(null); // Reset selected project if the user changes input
    };

    // Handle selecting a project from the filtered list
    const handleProjectSelect = (project) => {
        setProjectName(project.name);
        setProjectDescription(project.description.length > 100 ? project.description.slice(0, 100) + '...' : project.description); // Truncate description
        setSelectedProject(project); // Set the selected project
        setFilteredProjects([]); // Hide the buttons once a project is selected
    };

    const handleDescriptionFocus = () => {
        if (selectedProject) {
            setProjectDescription(selectedProject.description); // Show full description
        }
        setShowFullDescription(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setSuccessMessage('');
        setLoading(true);

        const newProject = {
            name: projectName,
            description: projectDescription,
            user_id: user.id,
            is_active: true
        };

        try {
            const response = await fetch(`${apiUrl}/api/add-project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newProject)
            });

            const result = await response.json();

            if (response.ok && result.message === 'OK') {
                setSuccessMessage('Проект успешно создан!');

                // Update projects in context
                setProjects(prevProjects => {
                    const updatedProjects = [...prevProjects, result.project];
                    setGlobalSelectedProject(result.project); // Set the newly created project as selected
                    return updatedProjects;
                });

                setTimeout(() => navigate('/library'), 1000);
            } else {
                setError('Ошибка при создании проекта. Попробуйте еще раз.');
            }
        } catch (error) {
            console.error('Error creating project:', error);
            setError('Ошибка сети. Попробуйте позже.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='wrapper'>
            <div className='wrapper1'>
                {loading ? (
                    <LoadingState />
                ) : (
                    <form onSubmit={handleSubmit} className="project-form">
                        <div className="tab-block-header">
                            <h1>Создайте ваш первый проект</h1>
                        </div>
                        <div className="form-group">
                            <label htmlFor="projectName">Название проекта:</label>
                            <input
                                type="text"
                                id="projectName"
                                ref={inputRef}
                                value={projectName}
                                onFocus={handleFocus} // Show project buttons on focus
                                onChange={handleChange} // Filter projects as user types
                                style={{
                                    minWidth: '150px',
                                    maxWidth: '100%',
                                    fontSize: getFontSize(projectName.length) // Dynamic font size for project name
                                }}
                                placeholder="Введите название"
                                required
                            />
                            {/* Render buttons for filtered projects */}
                            {filteredProjects.length > 0 && (
                                <div className="project-buttons">
                                    {filteredProjects.map((project) => (
                                        <button
                                            key={project.id}
                                            type="button"
                                            className="project-button"
                                            onClick={() => handleProjectSelect(project)}
                                        >
                                            {project.name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Description field is always visible */}
                        <div className="form-group">
                            <label htmlFor="projectDescription">Описание проекта:</label>
                            <textarea
                                id="projectDescription"
                                ref={textareaRef}
                                value={projectDescription}
                                onFocus={handleDescriptionFocus} // Expand description on focus if it's truncated
                                onChange={(e) => setProjectDescription(e.target.value)}
                                style={{
                                    minHeight: '50px',
                                    maxHeight: showFullDescription ? '300px' : '100px',
                                    overflowY: 'auto',
                                    fontSize: getFontSize(projectDescription.length)
                                }}
                                placeholder="Введите описание"
                                required
                            />
                        </div>

                        {/* Show appropriate action button */}
                        <button type="submit" className="action-button">
                            {selectedProject ? 'Продолжить' : 'Сохранить и продолжить'}
                        </button>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </form>
                )}
            </div>
        </div>
    );
};

export default Onboarding;
